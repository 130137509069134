// Libs
import React, { useEffect, useState } from "react";
import { useNotify, useRecordContext, useTranslate } from "react-admin";
import ExportButton from "../../../components/Buttons/ExportButton";
import { export_row_to_excel } from '../../../components/aegeon/helper';
import useGetTimeseriesProvider from '../../aegeon/timeseries/useGetTimeseriesProvider';
import GetTimeseriesAsync from "./GetTimeseriesAsync";

const moment = require('moment');

const DeviceExportButton = (props) => {
  const { onClick } = props;

  const t = useTranslate();
  const record = useRecordContext();
  const notify = useNotify();

  const [asyncData, setAsyncData] = useState({ data: [], status: "IDLE" });
  const [completeAsyncData, setCompleteAsyncData] = useState(false);

  const startDateTenyears = moment().set({ year: moment().year() - 10, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const startDate2023Year = moment().set({ year: 2023, month: 0, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  const endDateToday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const filterTenYears = { startDate: startDateTenyears, endDate: endDateToday };

  const [exporting, setExporting] = useState(false);

  // ****************************************************************************************************
  // Prepare All Calls to the Export API
  // ****************************************************************************************************
  let fastResponseProperties = [
    {
      name: "fusioned_energy",
      aggregation: { period: 'MONTH', operation: 'SUM' },
      export_schema: { header: ["pdl", "timestamp", "valeur", "unité"] }
    },
    {
      name: "manual_bills",
      aggregation: undefined,
      export_schema: { header: ["pdl", "timestamp", "valeur", "unité"] }
    },
    {
      name: "third_party_energy",
      aggregation: undefined,
      export_schema: { header: ["pdl", "timestamp", "valeur", "unité"] }
    },
    {
      name: "billed_energy",
      aggregation: { period: 'MONTH', operation: 'SUM' },
      export_schema: { header: ["pdl", "timestamp", "valeur", "unité"] }
    },
    {
      name: "informative_energy",
      aggregation: undefined,
      export_schema: { header: ["pdl", "timestamp", "valeur", "unité"] }
    },
  ];

  fastResponseProperties = fastResponseProperties.map((property) => {
    property.id = record.properties.find((element) => element.name === property.name)?.id;
    property.unit = record.properties.find((element) => element.name === property.name)?.unit;
    return property;
  });

  const billed_energy_device = ["grdfMeter", "enedisMeter"].includes(record?.deviceType);

  // All calls to the API - This is done like this to avoid calling the API when not needed and due to hook constaints
  fastResponseProperties[0].dataProvider = useGetTimeseriesProvider('devices', {
    deviceId: record.id,
    propertyId: fastResponseProperties[0].id,
    filter: filterTenYears,
    aggregation: fastResponseProperties[0].aggregation,
  }, { enabled: exporting && fastResponseProperties[0].id !== undefined });

  fastResponseProperties[1].dataProvider = useGetTimeseriesProvider('devices', {
    deviceId: record.id,
    propertyId: fastResponseProperties[1].id,
    filter: filterTenYears,
    aggregation: fastResponseProperties[1].aggregation,
  }, { enabled: exporting && fastResponseProperties[1].id !== undefined });

  fastResponseProperties[2].dataProvider = useGetTimeseriesProvider('devices', {
    deviceId: record.id,
    propertyId: fastResponseProperties[2].id,
    filter: filterTenYears,
    aggregation: fastResponseProperties[2].aggregation,
  }, { enabled: exporting && fastResponseProperties[2].id !== undefined });

  fastResponseProperties[3].dataProvider = useGetTimeseriesProvider('devices', {
    deviceId: record.id,
    propertyId: fastResponseProperties[3].id,
    filter: filterTenYears,
    aggregation: fastResponseProperties[3].aggregation,
  }, { enabled: exporting && fastResponseProperties[3].id !== undefined && billed_energy_device });

  fastResponseProperties[4].dataProvider = useGetTimeseriesProvider('devices', {
    deviceId: record.id,
    propertyId: fastResponseProperties[4].id,
    filter: filterTenYears,
    aggregation: fastResponseProperties[4].aggregation,
  }, { enabled: exporting && fastResponseProperties[4].id !== undefined });

  useEffect(() => {
  }, [exporting,]);

  // Handle Success
  useEffect(() => {
    let status = [];
    let data_list = [];

    // Loading data
    fastResponseProperties.forEach((property) => {
      switch (property.dataProvider.status) {
        case "success":
          if (property.dataProvider.timeseries !== undefined) {
            status.push("success");

            let data = property.dataProvider;
            data.timeseries = data.timeseries.map((element) => {
              const europeParisDate = moment(element.time).tz('Europe/Paris').format('YYYY-MM-DD');
              return { ...element, time: europeParisDate };
            });
            data_list.push({
              serie: property.name,
              unit: property.unit,
              data: data.timeseries.map((element) => {
                return {
                  time: element.time,
                  value: element.value
                }
              })
            });
          }
          else {
            status.push("failed");
          }
          break;
        case "loading":
          status.push("loading");
          break;
        case "idle":
          status.push("idle");
          break;
        default:
          break;
      }
    });

    // Handle AscyData status This is useful just for enedisMeter
    if (completeAsyncData) {
      status.push("success");
      data_list.push(asyncData);
    }
    else if (record?.deviceType !== "enedisMeter") {
      status.push("success");
    }
    else {
      status.push("loading");
    }

    // 10 years table
    const headers_10years = ["2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023"];
    const data_10years = headers_10years.map((header) => {
      return {
        time: header,
        value: record.airtable[`consumption_${header}_10years_in_kwh_pcs`] ? record.airtable[`consumption_${header}_10years_in_kwh_pcs`] : ""
      }
    });
    // check if value in data_10years is not empty or "" to push to data_list
    if (!data_10years.every((element) => element.value === "")) {
      data_list.push({
        serie: "ten_years",
        unit: "kWh PCS",
        data: data_10years
      });
    }

    // Handle results of the API calls
    if (status.includes("failed")) {
      if (exporting === true) {
        setExporting(false);
        notify(t('devices.exports.error'), { type: "error" });
      }
    }
    // All elements are idle
    else if (status.every((element) => element === "idle")) {
      if (exporting === true) {
        setExporting(false);
        notify(t('devices.exports.warning'), { type: "warning" });
      }
    }
    // All elements in status are success
    else if (status.every((element) => element === "success" || element === "idle")) {
      if (exporting === true) {
        const success = exportExcel(data_list);
        if (success) {
          notify(t('devices.exports.success'), { type: "success" });
        } else {
          notify(t('devices.exports.warning'), { type: "warning" });
        }
        setExporting(false);
      }
    }
  }, [fastResponseProperties, exporting, completeAsyncData]);

  // Export data to excel
  const exportExcel = (data) => {
    var sheets_data = [];
    if (data !== null) {

      data.forEach((item) => {
        if (item.data.length > 0) {
          const sheetName = t(`devices.sources.${item.serie}`).slice(0, 30);
          let rows = [];

          if (item.schema) {
            rows = [Object.values(item.schema)];
            item.data.forEach((element) => {
              let row = [];
              Object.keys(item.schema).forEach((key) => {
                if (key.startsWith("data.")) {
                  row.push(element[key.split(".")[1]]);
                }
                else if (key.startsWith("item.")) {
                  row.push(item[key.split(".")[1]]);
                }
                else if (key.startsWith("record.")) {
                  row.push(record[key.split(".")[1]]);
                }
              });
              rows.push(row);
            });
          }
          else {
            rows = [["pdl", "timestamp", "valeur", "unité"]]
            item.data.forEach((element) => {
              rows.push([record.name,
              element.time,
              element.value,
              item.unit]);
            });
          }
          sheets_data.push({ sheetName: sheetName, data: rows });
        }
      });

      if (sheets_data.length > 0) {
        export_row_to_excel(sheets_data, `${t('devices.export.prefix')}${record.name}.xlsx`);
        return true;
      }
      return false;
    };
  };

  const callback = (data) => {

    if (data.status === "COMPLETE") {
      setAsyncData(data);
      // This schema is used to export the data to excel
      data.schema = {
        "record.name": "pdl",
        "data.time": "timestamp",
        "data.value": "valeur",
        "item.unit": "unité",
        "data.sampling_rate_in_min": "pas de temps in minutes",
        "data.nature": "nature"
      }
      // Trigger the useEffect to handle the data
      setCompleteAsyncData(true);
    }
  };

  const handleExport = () => {
    onClick();
    setExporting(true);
    setCompleteAsyncData(false);
  };

  return (
    <div>

      <ExportButton onClick={handleExport} disabled={exporting} />
      {
        exporting && record?.deviceType === "enedisMeter" &&
        <GetTimeseriesAsync name="active_power" chunked={true} onStreamCallback={callback} startDate={startDate2023Year} endDate={endDateToday} />
      }
    </div>
  );
};

export default DeviceExportButton;
