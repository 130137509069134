import { maxBy, minBy } from "lodash";

const toTimestamp = (strDate) => {
  const dt = new Date(strDate).getTime();
  return dt / 1000;
};

const getObjectToList = (dict) => {
  let list = [];
  for (const [key, value] of Object.entries(dict)) {
    list.push(value);
  }
  return list;
};

// Get Ids based on a specific type
const getDeviceIds = (dict, type) => {
  let list = [];
  for (const [key, value] of Object.entries(dict)) {
    list.push(value);
  }
  return list.map((item) => item.devices.filter((item) => item.type === type)).flat();
};

const getPropertyId = (dict, type) => {
  const property = dict.properties.filter((item) => item.name === type);
  if (property.length > 0) return property[0].id;
  return undefined;
};

// Get Ids based on a specific type
const getInitialPeriodRange = (periodAggregate) => {
  const today = new Date();
  const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
  let startDate = today;

  switch (periodAggregate) {
    case "raw":
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7, 0, 0, 0);
      break;
    case "day":
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(), 0, 0, 0);
      break;
    case "month":
      startDate = new Date(today.getFullYear() - 1, today.getMonth(), 1, 0, 0, 0);
      break;
    case "year":
      startDate = new Date(today.getFullYear() - 2, 0, 1, 0, 0, 0);
      break;
    default:
      startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
      break;
  }
  return [startDate, endDate];
};

const filterDate = (timestamp, periodAggregate) => {
  const date = new Date(timestamp);

  switch (periodAggregate) {
    case "day":
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    case "month":
      return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
    case "year":
      return new Date(date.getFullYear(), 1, 1, 0, 0, 0);
    default:
      return date;
  }
};

const filterDateLabel = (timestamp, periodAggregate) => {
  const monthNames = ["Jan", "Fev", "Mar", "Avr", "May", "Jun", "Jul", "Aout", "Sep", "Oct", "Nov", "Dec"];
  const date = new Date(timestamp);
  switch (periodAggregate) {
    case "day":
      return date.getDay() + "/" + date.getMonth() + "/" + date.getFullYear();
    case "month":
      return monthNames[date.getMonth()] + "/" + date.getFullYear();
    case "year":
      return date.getFullYear();
    default:
      return timestamp;
  }
};

function getTableMin(data) {
  return data.length > 0
    ? minBy(data, function (o) {
        return o[2];
      })[2]
    : -1;
}

function getTableMax(data) {
  return data.length > 0
    ? maxBy(data, function (o) {
        return o[2];
      })[2]
    : -1;
}

function aggregateTimeseriesByField(data, field) {
  data[0]["result"] = undefined;
  let agg_data = data.reduce((acc, item) => {
    let acc_ts_dict = {};
    if (!acc["result"]) {
      acc.timeseries.forEach((i) => {
        acc_ts_dict[i["time"]] = i["value"];
      });
      acc["result"] = {};
      acc["result"][acc[field]] = acc_ts_dict;
    }

    // Create a dictionary with ts as key and value as value
    let item_ts_dict = {};
    item.timeseries.forEach((i) => {
      item_ts_dict[i["time"]] = i["value"];
    });

    // Get current dictionary for the item
    acc_ts_dict = acc["result"][item[field]] || {};

    // # iterate over acc_ts_dict and item_ts_dict and sum the values
    for (const time in acc_ts_dict) {
      if (item_ts_dict[time]) {
        acc_ts_dict[time] += item_ts_dict[time];
      }
    }
    // Get keys that are not in acc_ts_dict and add them to acc_ts_dict
    for (const time in item_ts_dict) {
      if (!acc_ts_dict[time]) {
        acc_ts_dict[time] = item_ts_dict[time];
      }
    }

    acc["result"][item.name] = acc_ts_dict;
    return acc;
  });
  return agg_data;
}

function convertTimeseriesObjectToList(data) {
  let list = [];
  for (const key in data) {
    let timeseries = [];
    for (const time in data[key]) {
      timeseries.push({
        time: time,
        value: data[key][time],
      });
    }
    list.push({
      name: key,
      timeseries: timeseries,
    });
  }
  return list;
}

const aggregateDiff = (itemsList, itemRef) => {
  const moment = require("moment");

  // get all individual item.timeseries.time
  let allTimes = {};

  // Remove ID diff if it exists
  const aggItems = itemsList.filter((item) => item.id !== "diff");

  aggItems?.forEach((item) => {
    item.timeseries?.forEach((timeserie) => {
      const time = moment(timeserie.time).format("YYYY-MM");
      if (!allTimes[time]) {
        allTimes[time] = 0;
      }
      allTimes[time] += timeserie.value;
    });
  });

  let timeseriesDiff = [];
  itemRef?.forEach((timeserie) => {
    const time = moment(timeserie.time).format("YYYY-MM");
    if (!allTimes[time]) {
      allTimes[time] = 0;
    }
    const diffValue = timeserie.value - allTimes[time];
    timeseriesDiff.push({
      time: time,
      value: diffValue,
    });
  });
  const itemDiff = {
    id: "diff",
    name: "diff",
    timeseries: timeseriesDiff,
  };

  aggItems.push(itemDiff);

  return aggItems;
};

const calculateTotalColumns = (lastMonths, rows, ignoredColumns) => {
  let totalColumns = [];

  lastMonths.forEach((month) => {
    let value = 0;
    rows.forEach((row) => {
      if (!ignoredColumns.includes(row.values[0])) {
        if (Number.isFinite(row.values[lastMonths.indexOf(month) + ignoredColumns.length])) {
          value += row.values[lastMonths.indexOf(month) + ignoredColumns.length];
        }
      }
    });
    totalColumns.push({ month: month, value: value });
  });
  // Add total of total column
  let total = totalColumns.reduce((a, b) => a + b.value, 0);
  totalColumns.push({ month: "total", value: total });

  return totalColumns;
};

export {
  toTimestamp,
  getObjectToList,
  getDeviceIds,
  getPropertyId,
  getInitialPeriodRange,
  filterDate,
  filterDateLabel,
  getTableMin,
  getTableMax,
  aggregateTimeseriesByField,
  convertTimeseriesObjectToList,
  aggregateDiff,
  calculateTotalColumns
};
